var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer-area"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row g-3 align-items-center"},[(_vm.isShowAll)?_c('div',{staticClass:"col-lg-4 col-md-5"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"col-lg-8 col-md-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5 col-md-6"},[(_vm.isShowAll)?_c('div',{staticClass:"row g-2"},[_vm._m(1),_vm._m(2)]):_vm._e()]),_vm._m(3)])])])]),_c('div',{staticClass:"socialbox"},[(_vm.backLink !== '')?_c('div',{staticClass:"phone iconbox"},[_c('button',{staticClass:"btn-back",on:{"click":_vm.gotoDealTabPage}},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]):_vm._e(),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"footer-wizget"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%94%D7%96%D7%9E%D7%A0%D7%94","target":"_blank"}},[_vm._v("בקשה לביטול הזמנה")])]),_c('li',[_c('a',{attrs:{"href":"/%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA_%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA","target":"_blank"}},[_vm._v("מדיניות פרטיות")])]),_c('li',[_c('a',{attrs:{"href":"/%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%9B%D7%9C%D7%9C%D7%99%D7%99%D7%9D_%D7%95%D7%94%D7%92%D7%91%D7%9C%D7%AA_%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA","target":"_blank"}},[_vm._v("תנאים כלליים והגבלת אחריות")])]),_c('li',[_c('a',{attrs:{"href":"http://www.facebook.com/people/%D7%91%D7%95%D7%90-%D7%A0%D7%A2%D7%95%D7%A3/100081443465668/?paipv=0","target":"_blank"}},[_vm._v("מידע לנוסע")])]),_c('li',[_c('a',{attrs:{"href":"/%D7%94%D7%A1%D7%93%D7%A8%D7%99_%D7%A0%D7%92%D7%99%D7%A9%D7%95%D7%AA","target":"_blank"}},[_vm._v("הסדרי נגישות")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"applogo"},[_c('a',{attrs:{"href":"https://apps.apple.com/il/app/%D7%91%D7%95%D7%90-%D7%A0%D7%A2%D7%95%D7%A3/id970349017"}},[_c('img',{attrs:{"src":"/assets/img/app/appstore.png","alt":"appstore"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"applogo"},[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.flyingcarpet&hl=he&gl=US&pli=1"}},[_c('img',{attrs:{"src":"/assets/img/app/googleplay.png","alt":"googleplay"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-7 col-md-6"},[_c('div',{staticClass:"footerlogo"},[_c('img',{attrs:{"src":"/assets/img/app/logo-desktop.png","alt":"Bonauf"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone iconbox"},[_c('a',{attrs:{"href":"tel:035139924"}},[_c('img',{attrs:{"src":"/assets/img/app/phone.png","alt":"phone"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone iconbox"},[_c('a',{attrs:{"href":"mailto:sherut@flying.co.il"}},[_c('img',{attrs:{"src":"/assets/img/app/email.png","alt":"email"}})])])
}]

export { render, staticRenderFns }