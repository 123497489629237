<template>
  <footer class="footer-area">
    <div class="container">
      <div class="row g-3 align-items-center">
        <div class="col-lg-4 col-md-5" v-if="isShowAll">
          <div class="row">
            <div class="col-12">
              <div class="footer-wizget">
                <ul>
                  <li><a href="/%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%94%D7%96%D7%9E%D7%A0%D7%94" target="_blank">בקשה לביטול הזמנה</a></li>
                  <li><a href="/%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA_%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA" target="_blank">מדיניות פרטיות</a></li>
                  <li><a href="/%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%9B%D7%9C%D7%9C%D7%99%D7%99%D7%9D_%D7%95%D7%94%D7%92%D7%91%D7%9C%D7%AA_%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA" target="_blank">תנאים כלליים והגבלת אחריות</a></li>
                  <li><a href="http://www.facebook.com/people/%D7%91%D7%95%D7%90-%D7%A0%D7%A2%D7%95%D7%A3/100081443465668/?paipv=0" target="_blank">מידע לנוסע</a></li>
                  <li><a href="/%D7%94%D7%A1%D7%93%D7%A8%D7%99_%D7%A0%D7%92%D7%99%D7%A9%D7%95%D7%AA" target="_blank">הסדרי נגישות</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-7">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <div class="row g-2" v-if="isShowAll">
                <div class="col-6">
                  <div class="applogo">
                    <a href="https://apps.apple.com/il/app/%D7%91%D7%95%D7%90-%D7%A0%D7%A2%D7%95%D7%A3/id970349017"><img src="/assets/img/app/appstore.png" alt="appstore"></a>
                  </div>
                </div>
                <div class="col-6">
                  <div class="applogo">
                    <a href="https://play.google.com/store/apps/details?id=com.flyingcarpet&hl=he&gl=US&pli=1"><img src="/assets/img/app/googleplay.png" alt="googleplay"></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-6">
              <div class="footerlogo">
                <img src="/assets/img/app/logo-desktop.png" alt="Bonauf">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="socialbox">
      <div class="phone iconbox" v-if="backLink !== ''">
        <button class="btn-back"  @click="gotoDealTabPage">
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
      <div class="phone iconbox">
        <a href="tel:035139924"><img src="/assets/img/app/phone.png" alt="phone"></a>
      </div>
      <div class="phone iconbox">
        <a href="mailto:sherut@flying.co.il"><img src="/assets/img/app/email.png" alt="email"></a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterDesktop',
  props: {
    backLink: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'GET_CURRENT_PAGE',
    }),
    isShowAll() {
      return this.currentPage === 'deal-filter' || this.currentPage === 'home';
    },
  },
  methods: {
    gotoDealTabPage() {
      this.$router.push(this.backLink);
    }
  }
};
</script>

<style lang="scss" scoped>
  .footer-area {
    background: #45b8e8;
    background: -webkit-linear-gradient(to right, #45b8e8, #38b1e3);
    background: linear-gradient(to right, #45b8e8, #38b1e3);
    padding: 20px 0;

    .footer-wizget {
      ul {
        margin:0; padding:0; list-style:none;

        li {
          display: block;

          a {
            font-family: 'ploniregularaaa';
            font-size: 28px;
            color: #FFF;
            text-decoration: none;
            line-height: 34px;
          }
          a:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .footerlogo {
      max-width: 410px;
      margin-right: auto;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    .applogo img {
      max-width: 100%;
      width: 100%;
    }
    .socialbox {
      display: flex;
      position: fixed;
      max-width: 200px;
      width: 100%;
      top: 0px;
      padding: 14px;
      z-index: 9;
      justify-content: space-around;

      .phone.iconbox{
        margin-bottom: 15px;
        max-width: 50px;
      }

      img {
        width: 100%;
        min-width: 100%;
      }

      .btn-back {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        font-size: 30px;
        color: #FFF;

        i {
          text-shadow: 1px 1px 2px rgba(0,0,0,1);
        }
      }
    }
  }

</style>
